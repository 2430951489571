import React from 'react';
import TextValidator from '../../shared/TextValidator';
import { connect } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core';
import { sendDemoRequest } from '../../actions/sendDemoRequest';

class LoginForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			email: '',
			isAuth: false,
			apellido: '',
			telefono: '',
			status: "",
			contactway: "",
		}
	}
	handleChange = (e, type) => {
		if (type === 'email') {
			this.setState({
				email: e.target.value,
			})
		}
		if (type === 'apellido') {
			this.setState({
				apellido: e.target.value,
			})
		}
		if (type === 'telefono') {
			this.setState({
				telefono: e.target.value,
			})
		}

		if (type === 'username') {
			this.setState({
				username: e.target.value,
			})
		}

	}
	changeDrowpdown = (e) => {
		console.log(e.target.value)
		this.setState({
			contactway: e.target.value
		})
	}
	clickEvent = () => {
		this.props.sendDemoRequest(this.state.username,
			this.state.email,
			this.state.telefono,
			this.state.apellido,
			this.state.contactway)
		this.setState({
			status: this.props.demoRequest.status
		})

	}
	render() {
		return (
			<div className="email-form card">
				<ValidatorForm
					className="form"
					ref="form"
					onSubmit={() => this.clickEvent()}
				>
					<div className="email-inputs">
						Nombre
				<TextValidator
							onChange={(e) => this.handleChange(e, 'username')}
							name="nombre"
							value={this.state.username}
							validators={['required', 'isString']}
							errorMessages={['El nombre es requerido']}
						/>
						Apellido
				<TextValidator
							onChange={(e) => this.handleChange(e, 'apellido')}
							name="apellido"
							value={this.state.apellido}
							validators={['required', 'isString']}
							errorMessages={['El apellido es requerido']}
						/>
						Telefono
				<TextValidator
							onChange={(e) => this.handleChange(e, 'telefono')}
							name="telefono"
							value={this.state.telefono}
							validators={['required', 'isString']}
							errorMessages={['El telefono es requerido']}
						/>
						Correo
				<TextValidator
							onChange={(e) => this.handleChange(e, 'email')}
							name="email"
							type="email"
							value={this.state.email}
							validators={['required', 'isString']}
							errorMessages={['El correo es requerido', 'test']}
						/>
						<div className="form-select">
							Como nos encontraste:
						<select onChange={(e) => this.changeDrowpdown(e)}>
								<option default value="sin seleccionar">Seleccione una opcion</option>
								<option value="amigo">Un amigo</option>
								<option value="facebook">Facebook</option>
								<option value="sitioweb">Sitio Web</option>
							</select>
						</div>
					</div>
					<div className="div-button-form">
						<button
							className="button-email"
							type="submit"
						>Enviar</button>
					</div>
				</ValidatorForm>
				{this.state.status === 201 && <h5>Enviado Correctamente</h5>}
				{this.state.status === 400 && <h5>Error enviando el correo, contacte: info@tc-exports.com</h5>}
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	/* istanbul ignore next */
	sendDemoRequest: (name, email, telefono, apellido, contactway) => dispatch(sendDemoRequest(name, email, telefono, apellido, contactway)),
});
/* istanbul ignore next */
const mapStateToProps = ({ demoRequest }) => {
	return { demoRequest }
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

LoginForm.defaultProps = {
	styleName: "",
	value: "",
};
