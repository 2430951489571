import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from "react-router-dom";
import logo from '../../assets/logo2.png'

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  logout = () => {
    sessionStorage.clear();
    window.location.href = '/login'
  }
  render() {
    return (
      <div className="header">
        <Navbar color="light" className="height-header bg-white" light expand="md">
          <NavbarBrand href="/"><img src={logo} alt="Logo" className="img-logo"></img></NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {/* <NavItem className="nav-links">
                <Link to="/orden" className="nav-link-color flex-display" >
                  <i class="material-icons">
                    directions_boat
                  </i>Mis embarques</Link>
              </NavItem> */}
              <NavItem className="nav-links">

                <Link to="/facturas_e" className="nav-link-color flex-display">
                  <i class="material-icons">
                    topic
                  </i>Facturas</Link>
              </NavItem>
              <NavItem className="nav-links">

                <Link to="/tarifas_e" className="nav-link-color flex-display">
                  <i class="material-icons">
                  attach_money
                  </i>Tarifas</Link>
              </NavItem>
              <NavItem className="nav-links">

                <Link to="/solicitar" className="nav-link-color flex-display">
                  <i class="material-icons">
                    add
                  </i>{sessionStorage.getItem('userType')==='4'? 'Cotizaciones' : 'Solicitar embarque'}</Link>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="nav-link-color flex-display">
                  <i class="material-icons">
                    account_circle
                  </i>
                  {sessionStorage.getItem("userFullName")}
                </DropdownToggle>
                <DropdownMenu right>
									<DropdownItem>
									<Link to="/">
                  Embarques</Link>
									</DropdownItem>
                  <DropdownItem>
                  <Link to="/facturas_e">
                  Facturas</Link>
									</DropdownItem>
                  <DropdownItem>
                  <Link to="/tarifas_e">
                  Tarifas</Link>
									</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.logout}>
                    Salir
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}