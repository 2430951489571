import { GET_ALL_SHIPMENTS } from '../actionTypes'
export default (state = {}, action) => {

	if (action.type === GET_ALL_SHIPMENTS) {
		if (action.payload) {
			return action.payload;
		}
		return state;
	}
	return state;
}