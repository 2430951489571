let backendHost;

const hostname = window && window.location && window.location.hostname;
if (hostname === 'production url') {
	backendHost = 'production host';
} else if (hostname === 'https://tc-web-app.firebaseapp.com') {
	backendHost = 'https://peaceful-hollows-60475.herokuapp.com';
}
else if (hostname === 'localhost') {
	backendHost = 'http://localhost:8000';
}

else {
	backendHost = 'https://peaceful-hollows-60475.herokuapp.com';
}

export const apiUrl = backendHost;