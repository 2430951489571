import { SEND_DEMO_REQUEST } from '../actionTypes';
import http from '../api/apiConfig';

export const sendDemoRequest = (name, email, telefono, apellido, contactway) => {
	return (dispatch) => {
		dispatch({
			type: 'fetching',
			status: 'pending'
		})
		getShipmentMethod(name, email, telefono, apellido, contactway)
			.then(demoRequest => {
				dispatch({
					type: SEND_DEMO_REQUEST,
					payload: demoRequest
				})
			})
	}
}

const getShipmentMethod = async (name, email, telefono, apellido, contactway) => {
	const shipmentData = await http.post('./demoRequest', {
		name,
		email,
		telefono,
		apellido,
		contactway
	})
		.then((demoReq) => {
			return demoReq;
		})

	return shipmentData;
}