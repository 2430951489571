import { CREATE_EMBARQUE } from '../actionTypes'
export default (state = {}, action) => {

	if (action.type === CREATE_EMBARQUE) {
		if (action.payload) {
			console.log('action create embarque', action)
			return action.payload;
		}
		return state;
	}
	return state;
}