import { SEND_PDF_DOC, LOADING_PDF } from '../actionTypes'
export default (state = { loading: false }, action) => {

  if (action.type === SEND_PDF_DOC) {
    if (action.payload) {
      return action.payload;
    }
    return state;
  }
  if (action.type === LOADING_PDF) {
    return { loading: true }
  }
  return state;
}