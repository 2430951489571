import { AUTH_USER } from '../actionTypes'
export default (state = {}, action) => {
	let userData = {
		email: null,
		userName: null,
		authorized: false,
	};
	if(action.type === "getting_data"){
		userData = {
			...action.payload,
			status: action.status,
		}
		return userData;
	}
	if (action.type === AUTH_USER) {
	
		if (action.payload) {

			if (action.payload) {
				userData = {
					...action.payload,
					authorized: true,
				}
			}
		}
		if (action.error) {
				userData = {
					...action.payload,
					error: action.error
				}
			
		}
		return userData;
	}
	return state;
}