import React from 'react';
import { Alert } from 'reactstrap';

class AlertExample extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: true
		};

		this.onDismiss = this.onDismiss.bind(this);
	}

	onDismiss() {
		this.setState({ visible: false });
	}

	render() {
		return (
			<Alert color="danger" isOpen={this.props.showError} className="danger-alert-custom" fade={true} toggle={this.props.closeWarning}>
				{this.props.message}
			</Alert>
		);
	}
}

export default AlertExample;