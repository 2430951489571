import { applyMiddleware, compose, createStore } from 'redux';
import reducers from '../reducers/index';
import createHistory from 'history/createHashHistory';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';


const history = createHistory();
const routeMiddleware = routerMiddleware(history);
const loadFromLocalStorage = () => {
	try {
		const serializedState = sessionStorage.getItem('state')
		if (serializedState === null) return undefined
		return JSON.parse(serializedState)
	}
	catch (e) {
		console.log(e)
		return undefined
	}
}
const middlewares = [routeMiddleware, thunk];

const persistedState = loadFromLocalStorage()
export default function configureStore() {
	const store = createStore(reducers, persistedState,
		compose(applyMiddleware(...middlewares)));

	// sagaMiddleware.run(rootSaga);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../reducers/index', () => {
			const nextRootReducer = require('../reducers/index');
			store.replaceReducer(nextRootReducer);
		});
	}
	return store;
}
export { history };
