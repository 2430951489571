import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import NavigationBar from './components/NavigationBar/navbar';
import Profile from './modules/Profile/userProfile';
import LoginComponent from './modules/Login/Login';
import { connect } from 'react-redux';
import Alert from './components/Alert/alert'
import App from './App';
import EmailForm from './modules/EmailWebSite/form';
import Embarques from './modules/EmbarquesNoLogin/embarqueslink'
import Facturas from './modules/facturasNoLogin/vistaFacturasNoLogin'
import Tarifas from './modules/tarifasNoLogin/tarifas'



class RootRouter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
			isAuth: true,
			error: false,
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
			isAuth: nextProps.userLoggedIn.authorized,
			error: nextProps.userLoggedIn.error,
			loading: nextProps.userLoggedIn.status === "pending"
    })
  }
  closeError = () => {
    this.setState({
      isAuth: true
    })
  }
  search = () => {
    const params = new URL(window.location.href).searchParams;
const idT = params.get('eid');
    if(!idT){
      return true;
    }
    return false;
  }
  render() {
    
    return (
      <Router>
        {this.state.error ? <Alert
          message={this.state.error}
          showError={this.state.error ? true : false}
          closeWarning={() => this.closeError()} /> : ''}
        {(this.state.error === true) ? <Alert
          message={this.state.error}
          showError={true}
          closeWarning={() => this.closeError()} /> : ''}
        {(window.location.pathname !== '/login' && window.location.pathname !== '/requestDemo' && sessionStorage.getItem('userLink') && this.search()) ? <NavigationBar /> : ''}
        < Route path="/" exact component={sessionStorage.getItem('userLink') === null ? LoginComponent : App} />
        < Route path="/facturas" component={Facturas} />
        < Route path="/solicitar" component={sessionStorage.getItem('userLink') === null ? LoginComponent : App} />
        < Route path="/tarifas_e" component={sessionStorage.getItem('userLink') === null ? LoginComponent : App} />
        < Route path="/facturas_e" component={sessionStorage.getItem('userLink') === null ? LoginComponent : App} />
        <Route path="/login" component={LoginComponent} />
        <Route path="/perfil" component={sessionStorage.getItem('AuthUser') === null ? LoginComponent : Profile} />
        <Route path="/requestDemo" component={EmailForm} />
        <Route path="/embarque" component={Embarques} />
        <Route path="/tarifas" component = {Tarifas} />
      </Router>
    )
  }
}

const mapStateToProps = (state) => {
  const { userLoggedIn } = state;
  return {
    userLoggedIn
  }
}
export default connect(mapStateToProps)(RootRouter);