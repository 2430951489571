import React from 'react';
import axios from 'axios';
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';
import wLogo from '../../assets/whatsappLogo.png'
import { Link } from "react-router-dom";
import logo from '../../assets/logo2.png'
import Loader from '../../components/Loader/loader'

const customStyle={
  backgroundColor: "transparent", 
	fontFamily: "Times New Roman",
	maxHeight: "80vh"
}

export default class FacturaNoAuth extends React.Component {
  constructor(props){
    super(props)
    this.state ={
      link: '',
      loading: true,
      name: ''
		}
  }
  getData = async (id) => {
    const empresasRecord = await axios.get(`https://api.airtable.com/v0/appyoqS2nC185S6HQ/Empresas/${id}?api_key=keyNmRNp3M2oIpnfF`)
    this.setState({
      link: empresasRecord.data.fields.embedFacturas,
      name: empresasRecord.data.fields.Name
    })
  }
  stopLoader =  () => {
    this.setState({
      loading: false
    })
  }
  componentWillMount(){
    const params = new URL(window.location.href).searchParams;
    const idT = params.get('eid');
    this.getData(idT);
  }
  render() {
    return (
      <>
      {(this.state.link === '' &&  this.state.loading) ?  <Loader />: ''}
      <div className="header">
        <Navbar color="light" className="height-header bg-white" light expand="md">
          <NavbarBrand href="/login"><img src={logo} alt="Logo" className="img-logo"></img></NavbarBrand>
          <Collapse isOpen={true} navbar>
            <Nav className="ml-auto" navbar>
            <NavItem className="nav-links">
            <span className="centered-navbar-text"><i class="material-icons absolute-icon">
                    account_circle
                  </i>{this.state.name}</span>
</NavItem>
              <NavItem className="nav-links">

                <Link to="/login" className="nav-link-color flex-display">
                  <i class="material-icons">
                    login
                  </i>Login</Link>
              </NavItem>
              <NavItem className="nav-links">

                <a href="https://api.whatsapp.com/send?phone=50687441324" className="nav-link-color flex-display">
                <img src={wLogo} alt="WhatsLogo" className="wlogo"></img></a>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      <h1 className="no-login-view-header">Estado de cuenta</h1>
      <iframe title="facturas" class="airtable-embed" src={this.state.link}
			frameborder="0" 
			onmousewheel="" 
      width="100%" 
			height="800px"
			onLoad={this.stopLoader}
      style = {customStyle}/> 
      </>
    );
  }
}