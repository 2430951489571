import React from 'react';
import Logo from '../../assets/logo2.png'
import LoginForm from './LoginForm';



function App() {
	return (



		<div className="login-background">
			<div className="login-component card">

				<div className="img-placeholder">
					<img src={Logo} alt="theialogo" className="img-login" all="theia logo"></img>
				</div>
				<div className="form-login">
					<LoginForm />
				</div>

			</div>
		</div>




	);
}

export default App;