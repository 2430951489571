import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';
import Routes from './routingConfig';
import configureStore from './store';

// const saveToLocalStorage = (state) => {
//   try {
//     const serializedState = JSON.stringify(state);
//     sessionStorage.setItem('state', serializedState);
//   } catch (e) {
//     console.log(e)
//   }
// }

export const store = configureStore();
// store.subscribe(() => saveToLocalStorage(store.getState()))

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
