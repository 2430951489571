import { combineReducers } from 'redux';
/*import { routerReducer } from 'react-router-redux';*/
import AuthReducer from '../reducers/auth';
import GetAllShipmentsReducer from './getAllShipments';
import sendDemoRequest from './sendDemoRequest';
import newUser from './newUser';
import allUsers from './allUsers';
import deleteUser from './allUsers';
import createUser from './createEmbarque';
import companies from './getAllCompanies';
import sendPDF from './sendPDF';
import embarqueStatus from './embarqueStatus';



const reducers = combineReducers({
  /*routing: routerReducer,*/
  userLoggedIn: AuthReducer,
  allShipments: GetAllShipmentsReducer,
  demoRequest: sendDemoRequest,
  newUser: newUser,
  allUsers: allUsers,
  deleteUser: deleteUser,
  createUser: createUser,
  companies: companies,
  pdfStatus: sendPDF,
  embarqueStatus: embarqueStatus,
});

export default reducers;
