import { CHANGE_STATUS, CHANGE_STATUS_PENDING, CHANGE_STATUS_ERROR } from '../actionTypes'
export default (state = { loading: false }, action) => {

  if (action.type === CHANGE_STATUS) {
    if (action.payload) {
      return action.payload;
    }
    return state;
  }
  if (action.type === CHANGE_STATUS_PENDING) {
    return {
      loading: true,
    }
  }
  if (action.type === CHANGE_STATUS_ERROR) {
    return {
      error: true,
    }
  }
  return state;
}