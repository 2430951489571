export const AUTH_USER = "AUTH_USER";
export const GET_ALL_SHIPMENTS = "GET_SHIPMENTS";
export const SEND_DEMO_REQUEST = "SEND_DEMO_REQUEST";
export const CREATE_EMBARQUE = "CREATE_EMBARQUE";
export const SEND_STATUS_EMAIL = "SEND_STATUS_EMAIL";
export const CREATE_USER = "CREATE_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const DELETE_USER = "DELETE_USER"
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const SEND_PDF_DOC = "SEND_PDF_DOC";
export const LOADING_PDF = "LOADING_PDF"


// Modify Embarque status

export const CHANGE_STATUS_PENDING = "CHANGE_STATUS_PENDING";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR";