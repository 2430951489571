import React from 'react';
import { Container } from 'reactstrap';



function App() {
	return (

		<Container>
			<h1>Perfil de Usuario</h1>
		</Container>


	);
}

export default App;