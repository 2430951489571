import React from 'react';
import TextValidator from '../../shared/TextValidator';
import { connect } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core';
import { authenticate } from '../../actions/auth';
import Loader from '../../components/Loader/loader'

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
			isAuth: false,
			loading: false,
    }
  }
  handleChange = (e, type) => {
    if (type === 'password') {
      this.setState({
        password: e.target.value,
      })
    }
    else {
      this.setState({
        username: e.target.value
      })
    }

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.userLoggedIn.fullName) {
      sessionStorage.setItem('userFullName', nextProps.userLoggedIn.fullName);
      sessionStorage.setItem('linkFacturas', nextProps.userLoggedIn.linkFacturas ? nextProps.userLoggedIn.linkFacturas[0] : '');
      sessionStorage.setItem('linkTarifas', nextProps.userLoggedIn.linkTarifas ? nextProps.userLoggedIn.linkTarifas[0] : '');
			sessionStorage.setItem('userLink', nextProps.userLoggedIn ? nextProps.userLoggedIn.linkEmbarque : '');
			sessionStorage.setItem('userType', nextProps.userLoggedIn ? nextProps.userLoggedIn.userType : '');
			sessionStorage.setItem('linkNewEmbarque', nextProps.userLoggedIn ? nextProps.userLoggedIn.linkNewEmbarque : '');
      window.location.href = '/'
		}
		this.setState({
			loading: nextProps.userLoggedIn.status === "pending"
		})
  }
  clickEvent = () => {
    this.props.authenticate(this.state.username, this.state.password)
  }
  render() {
    return (
			<React.Fragment>
					{this.state.loading? <div className="loading-div"><Loader /></div>: ''}
					<ValidatorForm
        ref="form"
        onSubmit={() => this.clickEvent()}
      >
        <span>Correo</span>
				<TextValidator
          onChange={(e) => this.handleChange(e, 'username')}
          name="email"
          value={this.state.username}
          validators={['required', 'isString']}
          errorMessages={['El nombre de usuario es requerido']}
        />
        <span>Contraseña</span>
				<TextValidator
          onChange={(e) => this.handleChange(e, 'password')}
          name="password"
          type="password"
          value={this.state.password}
          validators={['required', 'isString']}
          errorMessages={['La contrasena es requerida', 'test']}
        />
        <div className="div-button-form">
          <button
            className="button-link button-search white-color"
            type="submit"
          >Ingresar</button>
        </div>
        <div>
        <div className="accessRequest">
        <a href="https://airtable.com/shrjD3sVF0pN22XMB">Solicitar acceso</a>
        </div>
        <div className="forgot-password">
        <a href=" https://airtable.com/shr70bXOfcGwQLvl8">¿Olvidó la contraseña?</a>
        </div>
        </div>
      </ValidatorForm>
			</React.Fragment>
      
    )
  }
}

const mapDispatchToProps = dispatch => ({
  /* istanbul ignore next */
  authenticate: (userName, password) => dispatch(authenticate(userName, password)),
});
/* istanbul ignore next */
const mapStateToProps = ({ userLoggedIn }) => {
  return { userLoggedIn }
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

LoginForm.defaultProps = {
  styleName: "",
  value: "",
};
