import { SEND_DEMO_REQUEST } from '../actionTypes'
export default (state = {}, action) => {

	if (action.type === SEND_DEMO_REQUEST) {
		if (action.payload) {
			console.log('action demo request', action)
			return action.payload;
		}
		return state;
	}
	return state;
}