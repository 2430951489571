import { GET_ALL_COMPANIES } from '../actionTypes'
export default (state = {}, action) => {

	if (action.type === GET_ALL_COMPANIES) {
		if (action.payload) {
			return action.payload;
		}
		return state;
	}
	return state;
}