import React from 'react';
import { css } from '@emotion/core';
// First way to import
import { RingLoader } from 'react-spinners';
// Another way to import
// import RingLoader from 'react-spinners/ClipLoader';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    position: absolute;
    top: 12%;
    right: 50%;
    z-index: 230;
`;

class AwesomeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  render() {
    return (
      <div className='sweet-loading'>
        <RingLoader
          css={override}
          sizeUnit={"px"}
          size={90}
          color={'#4FA8A5'}
          loading={this.state.loading}
        />
      </div>
    )
  }
}
export default AwesomeComponent;