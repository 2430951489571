import React from 'react'

//import all dashboard cards here

const customStyle={
  backgroundColor: "transparent", 
	fontFamily: "Times New Roman",
	maxHeight: "80vh"
  
}
const container = {
  margin:"2%",
	// border: "2px outset black" 

}

// we can add all the card that we want here to the dashboard
function dashboard(props) {
  return (
  <React.Fragment>
		  <div className = {props.loading ? 'display-none': 'shadow-v'} style = {container}>
			<iframe title="dashboardFrame" class="airtable-embed" src={props.link}
			frameborder="0" 
			onmousewheel="" 
      width="100%" 
			height="800px"
			onLoad={props.hideSpinner}
      style = {customStyle}/> 
    </div>
	</React.Fragment>
  );
}

export default dashboard;
