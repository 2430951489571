import React from 'react';
import { connect } from 'react-redux'
import Dashboard from './modules/Dashboard'
import Loader from './components/Loader/loader'


// we can add all the card that we want here to the dashboard
class  App extends React.Component {
	constructor(props){
		super(props);
		this.state ={
			loading: true
		}
	}
	hideSpinner = () => {
    this.setState({
      loading: false
    });
	};
	getLink = (path) => {
		let link;
		if(path === '/'){
			return link = sessionStorage.getItem("userLink");
		}
		if(path  === '/solicitar'){
      link = sessionStorage.getItem("linkNewEmbarque")
			return link;
    }
    if(path  === '/tarifas_e'){
      link = sessionStorage.getItem("linkTarifas")
			return link;
    }
    if(path  === '/facturas_e'){
      link = sessionStorage.getItem("linkFacturas")
			return link;
		}
		else{
			return link = sessionStorage.getItem("linkNewEmbarque")
		}


	}
  render(){
	console.log(sessionStorage.getItem("linkNewEmbarque"))
		return (

			<div className="dashboard-wrapper">
					{
						this.state.loading && <Loader />
					}
					<Dashboard 
					hideSpinner={this.hideSpinner}
					loading={this.state.loading}
					link={this.getLink(window.location.pathname)} 
					/>
				
			</div>
	
	
	
		);
	}
}

const mapStateToProps = ({ userLoggedIn }) => {
  return { userLoggedIn }
};
export default connect(mapStateToProps)(App);
